import { Dropbox, DropboxTeam } from 'dropbox';
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { createStructuredSelector } from 'reselect';

import GeneralConstants from '../../constants/general.constants';
import { EditFormContext } from '../../context/edit-form-context';
import NotFound from '../../pages/not-found/not-found.component';
import { fetchUserRoleStart } from '../../redux/user/user.actions';
import { selectCurrentUser, selectCurrentUserRoles } from '../../redux/user/user.selectors';
import CommentContainer from '../comments/comments.component';
import ErrorMessage from '../error/error.component';
import ProjectCalendar from '../project-calendar/project-calendar.component';
import ProjectFormFirstPage from '../project-form-steps/project-form-first-page.component';
import ProjectFormSecondPage from '../project-form-steps/project-form-second-page.component';
import ProjectFormThirdPage from '../project-form-steps/project-form-third-page.component';
import ProjectNewClient from '../project-new-client/project-new-client.component';
import Spinner from '../spinner/spinner.component';
import SuccessMessage from '../success/success.component';

const api = window.api;

const accessToken = 'iJKqyjtIgiAAAAAAAAAA1LQ5yhAZHOrvK7FAI62u_x0mCfOc6UOTOg825Wd1JSD9';

const dbObject = (dbCurrentUser, dbPathRoot) =>
	new Dropbox({
		fetch,
		accessToken,
		selectUser: dbCurrentUser.profile.team_member_id,
		pathRoot: dbPathRoot
	});

class EditProject extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 1,
			serverClientList: [],
			serverUserRoles: [],
			activeFormData: {},
			activeAddressData: [],
			serviceList: [],
			servicePersonList: [],
			serviceTypeAndMaterial: [],
			showServices: false,
			projectStatus: 'draft',

			showComments: false,
			comments: [],
			commentGroups: [],
			toggleCommentForm: false,
			selectedComment: '',
			selectedCommentID: null,
			commentTargetId: null,
			commentTargetGroup: null,
			commentLabel: '',
			uniqueTargetIDs: [],
			showDeleteConfirmID: null,
			showCommentGallery: null,

			dbLimit: 100,
			dbPathRoot: '',
			dbCurrentUser: {},
			dbFolderStructure: '',
			dbImages: [],
			dbImageThumbs: [],
			dbFiles: [],
			dbUploadDoc: [],
			dbUploadImg: [],
			projectDBimg: false,
			projectDBDoc: false,

			allUsers: [],

			trackedTime: [],

			categoryFormData: [],

			toggleCalendar: false,
			calendarProjectList: [],
			isUploadingImg: false,
			isUploadingDoc: false,
			isFetching: true,
			error: null,
			successMessage: false,
			errorMessage: false,
			unSavedAddressData: [],

			handlePageOneData: this.handlePageOneData,
			handlePageOneState: this.handlePageOneState,
			handlePageTwoData: this.handlePageTwoData,
			handlePageTwoState: this.handlePageTwoState,
			handleRemoveAddress: this.handleRemoveAddress,
			handleAddAddress: this.handleAddAddress,
			handleCreateDbObject: this.handleCreateDbObject,
			handleSetInitialState: this.handleSetInitialState,
			handleSetNextPageState: this.handleSetNextPageState,
			handleSetServerError: this.handleSetServerError,
			handleSetServicesToUnchecked: this.handleSetServicesToUnchecked,
			handleModifiedServiceAndPerson: this.handleModifiedServiceAndPerson,
			handleOnSubmit: this.handleOnSubmit,
			handleShowComments: this.handleShowComments,
			handleHideCommentForm: this.handleHideCommentForm,
			handleHideComments: this.handleHideComments,
			handleUpdateComments: this.handleUpdateComments,
			handleEditComment: this.handleEditComment,
			handleImageThumbs: this.handleImageThumbs,
			handleSendDbData: this.handleSendDbData,
			handleDropBoxUpload: this.handleDropBoxUpload,
			handleAttachmentChange: this.handleAttachmentChange,
			handleDocumentDownload: this.handleDocumentDownload,
			handleCommentToggleForm: this.handleCommentToggleForm,
			handleCommentSubmit: this.handleCommentSubmit,
			handleCommentChange: this.handleCommentChange,
			handleSelectGroupChange: this.handleSelectGroupChange,
			handleToggleDeleteConfirm: this.handleToggleDeleteConfirm,
			handleCommentDelete: this.handleCommentDelete,
			handleCommentEditMode: this.handleCommentEditMode,
			scrollToCommentID: this.scrollToCommentID,
			handleCommentGallery: this.handleCommentGallery,
			handleCloseGallery: this.handleCloseGallery,
			handleCategoryFormSubmit: this.handleCategoryFormSubmit,
			handleProductRemove: this.handleProductRemove,
			handlePageSwitch: this.handlePageSwitch,
			handleProjectStatusChange: this.handleProjectStatusChange,
			handleSetCategoryFormDataAndProjectStatus: this.handleSetCategoryFormDataAndProjectStatus,
			handleProjectStatusSubmit: this.handleProjectStatusSubmit,
			handleStepCommentCount: this.handleStepCommentCount,
			handleCalendarClick: this.handleCalendarClick,
			handleCalendarToggle: this.handleCalendarToggle,
			handleCalendarSubmit: this.handleCalendarSubmit,
			handleCalendarState: this.handleCalendarState,
			handleGoogleMapCallBack: this.handleGoogleMapCallBack,
			handleCreateNewClient: this.handleCreateNewClient,
			handleAddNewClient: this.handleAddNewClient,
			handleOrderTypeRemove: this.handleOrderTypeRemove,
			handleDropZoneAttachmentChange: this.handleDropZoneAttachmentChange,
			showAddNewClientOverlay: false
		};
	}

	handleCreateNewClient = (ev) => {
		ev.preventDefault();
		this.setState((prevState) => ({
			...prevState,
			showAddNewClientOverlay: !prevState.showAddNewClientOverlay,
			error: null
		}));
	};

	handleAddNewClient = (values) => {
		api.clients
			.put('clients', { ...values })
			.then((response) =>
				this.setState((prevState) => ({
					...prevState,
					serverClientList: [response, ...prevState.serverClientList],
					showAddNewClientOverlay: false,
					error: null
				}))
			)
			.catch((error) => this.handleSetServerError(error));
	};

	handleAddAddress = (address) => {
		if (address.id) {
			const filterAddress = this.state.activeAddressData.filter(
				(activeAddress) => activeAddress.id != address.id
			);
			api.clients
				.put('address', [address, ...filterAddress])
				.then((response) =>
					this.setState((prevState) => ({
						...prevState,
						activeAddressData: response
					}))
				)
				.catch((error) => this.handleSetServerError(error));
		} else {
			address['project_id'] = this.state.activeFormData.id;

			api.clients
				.put('address', [address])
				.then((response) => this.handleGoogleMapCallBack(response, address.iteration))
				.catch((error) => this.handleSetServerError(error));
		}
	};

	handleGoogleMapCallBack = (response, iteration) => {
		const data = response;
		data[0]['iteration'] = iteration;
		this.setState((prevState) => ({
			...prevState,
			activeAddressData: [...this.state.activeAddressData, ...data]
		}));
	};

	handleCalendarClick = async (ev, calendarDates) => {
		ev.preventDefault();
		this.setState((prevState) => ({ isFetching: true }));
		try {
			const calendarProjectList = await api.projects.get(
				`?datetime_range=6,6&fields=id,title,client_id,category,user_ids_transport,user_ids_assembly,user_ids_production,status,start_assembly_datetime,end_assembly_datetime,start_production_datetime,end_production_datetime,start_transport_datetime,end_transport_datetime&show_knapphus_brand=1`
			);

			await Promise.all([this.handleCalendarState(calendarProjectList, calendarDates)]);
		} catch (error) {
			this.handleSetServerError(error);
		}
	};

	handleCalendarState = (calendarProjectList, calendarDates) => {
		this.setState(
			(prevState) => ({
				...prevState,
				calendarProjectList,
				calendarDates,
				toggleCalendar: true,
				error: null,
				isFetching: false,
				errorMessage: false
			}),
			() => (document.body.style.overflowY = 'hidden')
		);
	};

	handleCalendarSubmit = async (values) => {
		this.setState((prevState) => ({ isFetching: true }));
		const {
			start_production_datetime: production_datetime_start,
			end_production_datetime: production_datetime_end,
			start_assembly_datetime: assembly_datetime_start,
			end_assembly_datetime: assembly_datetime_end,
			start_transport_datetime: transport_datetime_start,
			end_transport_datetime: transport_datetime_end
		} = values;

		let {
			id,
			title,
			category,
			client_id,
			type,
			start_production_datetime,
			end_production_datetime,
			start_assembly_datetime,
			end_assembly_datetime,
			start_transport_datetime,
			end_transport_datetime,
			status
		} = this.state.activeFormData;

		if (production_datetime_start != undefined) {
			start_production_datetime = this.timeToFormat(production_datetime_start);
		}
		if (production_datetime_end != undefined) {
			end_production_datetime = this.timeToFormat(production_datetime_end);
		}
		if (assembly_datetime_start != undefined) {
			start_assembly_datetime = this.timeToFormat(assembly_datetime_start);
		}
		if (assembly_datetime_end != undefined) {
			end_assembly_datetime = this.timeToFormat(assembly_datetime_end);
		}
		if (transport_datetime_start != undefined) {
			start_transport_datetime = this.timeToFormat(transport_datetime_start);
		}
		if (transport_datetime_end != undefined) {
			end_transport_datetime = this.timeToFormat(transport_datetime_end);
		}
		try {
			const project = await api.projects.put(id, {
				title,
				category,
				client_id,
				type,
				start_production_datetime,
				end_production_datetime,
				start_assembly_datetime,
				end_assembly_datetime,
				start_transport_datetime,
				end_transport_datetime,
				status
			});
			this.setState((prevState) => ({
				...prevState,
				activeFormData: project,
				error: null,
				isFetching: false,
				errorMessage: false,
				successMessage: true
			}));
			setTimeout(() => this.setState({ successMessage: false }), 1500);
		} catch (error) {
			this.handleSetServerError(error);
		}
	};

	timeToFormat = (time) => {
		const timestamp = Date.parse(time);

		const date = new Date(timestamp);
		return `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${
			date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
		} ${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}:${
			date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
		}`;
	};

	handleCalendarToggle = (ev) => {
		ev.preventDefault();
		this.setState(
			(prevState) => ({
				...prevState,
				toggleCalendar: !prevState.toggleCalendar
			}),
			() => (document.body.style.overflowY = 'auto')
		);
	};

	handleStepCommentCount = (page) => {
		let commentArray = [];

		let commentArraySize = [];
		if (page == 1) {
			commentArraySize = this.state.comments.filter(
				(comment) => comment.group == 'delivery_and_project' || comment.group == 'demolition_and_services'
			);
			commentArray = commentArraySize.length;
		} else {
			commentArraySize = this.state.comments.filter(
				(comment) =>
					comment.group != 'delivery_and_project' &&
					comment.group != 'demolition_and_services' &&
					comment.group != 'general_comment'
			);
			commentArray = commentArraySize.length;
		}
		return commentArray;
	};

	handleProjectStatusSubmit = async () => {
		try {
			const projectStatusResponse = await api.projects.put(`${this.state.activeFormData.id}/status`, {
				status: `${this.state.projectStatus}`
			});
			this.setState((prevState) => ({
				...prevState,
				projectStatus: projectStatusResponse.status,
				successMessage: true
			}));
			setTimeout(() => this.setState({ successMessage: false }), 1500);
		} catch (error) {
			this.handleSetServerError(error);
		}
	};

	handleProjectStatusChange = (value) => {
		this.setState((prevState) => ({
			...prevState,
			projectStatus: value
		}));
	};

	handlePageSwitch = (ev, page) => {
		ev.preventDefault();
		this.setState(
			(prevState) => ({
				...prevState,
				page
			}),
			() => window.scrollTo(0, 0)
		);
	};

	handleProductRemove = (remove, product, productGroup) => {
		if (product == undefined) {
			remove();
		} else if (this.state.categoryFormData.products[`${productGroup}`].list.items.value.length) {
			const newProductsList = this.state.categoryFormData.products[`${productGroup}`].list.items.value.filter(
				(item) => item.id != product.id
			);

			this.setState(
				(prevState) => ({
					...prevState,
					categoryFormData: {
						...prevState.categoryFormData,
						products: {
							...prevState.categoryFormData.products,
							[`${productGroup}`]: {
								...prevState.categoryFormData.products[`${productGroup}`],
								list: {
									...prevState.categoryFormData.products[`${productGroup}`].list,
									items: {
										...prevState.categoryFormData.products[`${productGroup}`].list.items,
										value: {
											...newProductsList
										}
									}
								}
							}
						}
					}
				}),
				() => remove()
			);
		} else {
			remove();
		}
	};

	handleOrderTypeRemove = (remove, order_type, productGroup) => {
		if (order_type == undefined) {
			remove();
		} else if (this.state.categoryFormData.order_type.knapphus[`${productGroup}`].list.items.value.length) {
			const newOrderTypeList = this.state.categoryFormData.order_type.knapphus[
				`${productGroup}`
			].list.items.value.filter((item) => item.id != order_type.id);

			this.setState(
				(prevState) => ({
					...prevState,
					categoryFormData: {
						...prevState.categoryFormData,
						order_type: {
							...prevState.categoryFormData.order_type,
							knapphus: {
								...prevState.categoryFormData.order_type.knapphus,
								[`${productGroup}`]: {
									...prevState.categoryFormData.order_type.knapphus[`${productGroup}`],
									list: {
										...prevState.categoryFormData.order_type.knapphus[`${productGroup}`].list,
										items: {
											...prevState.categoryFormData.order_type.knapphus[`${productGroup}`].list
												.items,
											value: {
												...newOrderTypeList
											}
										}
									}
								}
							}
						}
					}
				}),
				() => remove()
			);
		} else {
			remove();
		}
	};

	handleCategoryFormSubmit = async (values) => {
		const { categoryFormData } = this.state;

		const { id, category } = this.state.activeFormData;
		this.setState({ isFetching: true });

		if (values.order_type && values.order_type.dates.delivered.value == false) {
			values.order_type.dates.delivered_date.value = null;
		}
		if (values.sink && values.sink.dates.delivered.value == false) {
			values.sink.dates.delivered_date.value = null;
		}
		if (values.doorhandle && values.doorhandle.dates.delivered.value == false) {
			values.doorhandle.dates.delivered_date.value = null;
		}
		if (values.products) {
			if (values.products.oven.dates.delivered.value === false) {
				values.products.oven.dates.delivered_date.value = null;
			}
			if (values.products.top.dates.delivered.value === false) {
				values.products.top.dates.delivered_date.value = null;
			}
			if (values.products.dishwasher.dates.delivered.value === false) {
				values.products.dishwasher.dates.delivered_date.value = null;
			}
			if (values.products.micro.dates.delivered.value === false) {
				values.products.micro.dates.delivered_date.value = null;
			}
			if (values.products.fridge.dates.delivered.value === false) {
				values.products.fridge.dates.delivered_date.value = null;
			}
			if (values.products.freezer.dates.delivered.value === false) {
				values.products.freezer.dates.delivered_date.value = null;
			}
			if (values.products.ventilator.dates.delivered.value === false) {
				values.products.ventilator.dates.delivered_date.value = null;
			}
			if (values.products.various_extras_list.dates.delivered.value === false) {
				values.products.various_extras_list.dates.delivered_date.value = null;
			}
			if (values.products?.various_extras_list_2.dates.delivered.value === false) {
				values.products.various_extras_list_2.dates.delivered_date.value = null;
			}
			if (values.products?.various_extras_3.dates.delivered.value === false) {
				values.products.various_extras_3.dates.delivered_date.value = null;
			}
			if (values.products.recycling.dates.delivered.value === false) {
				values.products.recycling.dates.delivered_date.value = null;
			}
			const productListData = [];

			productListData.oven = values.products.oven.list.items.value.filter((data) => data.brand !== '__empty__');

			productListData.top = values.products.top.list.items.value.filter((data) => data.brand !== '__empty__');

			productListData.dishwasher = values.products.dishwasher.list.items.value.filter(
				(data) => data.brand !== '__empty__'
			);

			productListData.micro = values.products.micro.list.items.value.filter((data) => data.brand !== '__empty__');

			productListData.fridge = values.products.fridge.list.items.value.filter(
				(data) => data.brand !== '__empty__'
			);

			productListData.freezer = values.products.freezer.list.items.value.filter(
				(data) => data.brand !== '__empty__'
			);

			productListData.ventilator = values.products.ventilator.list.items.value.filter(
				(data) => data.brand != '__empty__'
			);

			productListData.recycling =
				values?.products?.recycling?.recyclingmode?.value === 'yes'
					? values.products.recycling.recyclingmode.value
					: [];

			productListData.various_extras_list = values?.products?.various_extras_list?.diverse?.value?.length
				? values.products.various_extras_list.diverse.value
				: [];

			productListData.various_extras_list_2 = values?.products?.various_extras_list_2?.EL_pillar?.value.length
				? values.products.various_extras_list_2.EL_pillar.value
				: [];

			productListData.various_extras_3 = values?.products.various_extras_3?.quooker?.value?.length
				? values.products.various_extras_3.quooker.value
				: [];

			const checkAllProductsBrandSelectionLength = !(
				productListData.oven.length < 1 &&
				productListData.top.length < 1 &&
				productListData.dishwasher.length < 1 &&
				productListData.micro.length < 1 &&
				productListData.fridge.length < 1 &&
				productListData.freezer.length < 1 &&
				productListData.ventilator.length < 1 &&
				productListData.various_extras_list.length < 1 &&
				productListData.various_extras_list_2.length < 1 &&
				productListData.various_extras_3.length < 1 &&
				productListData.recycling.length < 1
			);

			const productDelivered = [
				productListData.oven.length > 0 ? values.products.oven.dates.delivered.value : true,
				productListData.top.length > 0 ? values.products.top.dates.delivered.value : true,
				productListData.dishwasher.length > 0 ? values.products.dishwasher.dates.delivered.value : true,
				productListData.micro.length > 0 ? values.products.micro.dates.delivered.value : true,
				productListData.fridge.length > 0 ? values.products.fridge.dates.delivered.value : true,
				productListData.freezer.length > 0 ? values.products.freezer.dates.delivered.value : true,
				productListData.ventilator.length > 0 ? values.products.ventilator.dates.delivered.value : true,
				productListData.various_extras_list.length > 0
					? values.products.various_extras_list.dates.delivered.value
					: true,
				productListData.various_extras_list_2.length > 0
					? values.products.various_extras_list_2.dates.delivered.value
					: true,
				productListData.various_extras_3.length > 0
					? values.products.various_extras_3.dates.delivered.value
					: true,
				productListData.recycling.length > 0 ? values.products.recycling.dates.delivered.value : true,
				checkAllProductsBrandSelectionLength
			];

			const productOrdered = [
				productListData.oven.length > 0 ? values.products.oven.dates.ordered.value : true,
				productListData.top.length > 0 ? values.products.top.dates.ordered.value : true,
				productListData.dishwasher.length > 0 ? values.products.dishwasher.dates.ordered.value : true,
				productListData.micro.length > 0 ? values.products.micro.dates.ordered.value : true,
				productListData.fridge.length > 0 ? values.products.fridge.dates.ordered.value : true,
				productListData.freezer.length > 0 ? values.products.freezer.dates.ordered.value : true,
				productListData.ventilator.length > 0 ? values.products.ventilator.dates.ordered.value : true,
				productListData.various_extras_list.length > 0
					? values.products.various_extras_list.dates.ordered.value
					: true,
				productListData.various_extras_list_2.length > 0
					? values.products.various_extras_list_2.dates.ordered.value
					: true,
				productListData.various_extras_3.length > 0
					? values.products.various_extras_3.dates.ordered.value
					: true,
				productListData.recycling.length > 0 ? values.products.recycling.dates.ordered.value : true,
				checkAllProductsBrandSelectionLength
			];

			const productApproved = [
				productListData.oven.length > 0 ? values.products.oven.dates.approved.value : true,
				productListData.top.length > 0 ? values.products.top.dates.approved.value : true,
				productListData.dishwasher.length > 0 ? values.products.dishwasher.dates.approved.value : true,
				productListData.micro.length > 0 ? values.products.micro.dates.approved.value : true,
				productListData.fridge.length > 0 ? values.products.fridge.dates.approved.value : true,
				productListData.freezer.length > 0 ? values.products.freezer.dates.approved.value : true,
				productListData.ventilator.length > 0 ? values.products.ventilator.dates.approved.value : true,
				productListData.various_extras_list.length > 0
					? values.products.various_extras_list.dates.approved.value
					: true,
				productListData.various_extras_list_2.length > 0
					? values.products.various_extras_list_2.dates.approved.value
					: true,
				productListData.various_extras_3.length > 0
					? values.products.various_extras_3.dates.approved.value
					: true,
				productListData.recycling.length > 0 ? values.products.recycling.dates.approved.value : true,
				checkAllProductsBrandSelectionLength
			];

			const productDeliveredDates = [
				values.products.oven.dates.delivered_date.value,
				values.products.top.dates.delivered_date.value,
				values.products.dishwasher.dates.delivered_date.value,
				values.products.micro.dates.delivered_date.value,
				values.products.fridge.dates.delivered_date.value,
				values.products.freezer.dates.delivered_date.value,
				values.products.ventilator.dates.delivered_date.value,
				values.products.various_extras_list.dates.delivered_date.value,
				values.products.various_extras_list_2.dates.delivered_date.value,
				values.products.various_extras_3.dates.delivered_date.value,
				values.products.recycling.dates.delivered_date.value
			];

			const isAllTrue = (currentValue) => currentValue === true;

			const allDeliveredProductAreTrue = productDelivered.every(isAllTrue);

			const allOrderedProductAreTrue = productOrdered.every(isAllTrue);

			const allApprovedProductAreTrue = productApproved.every(isAllTrue);

			if (allDeliveredProductAreTrue === true) {
				let setDate = productDeliveredDates.reduce((a, b) => {
					return new Date(a) > new Date(b) ? a : b;
				});
				if (setDate == null) {
					setDate = new Date().toISOString().substring(0, 10);
				}
				values.products.dates.delivered.value = true;
				values.products.dates.delivered_date.value = `${setDate}`;
			} else {
				values.products.dates.delivered.value = false;
				values.products.dates.delivered_date.value = null;
			}
			values.products.dates.ordered.value = allOrderedProductAreTrue === true;
			values.products.dates.approved.value = allApprovedProductAreTrue === true;
		}

		const mergedValues = _.merge(categoryFormData, values);

		try {
			const formResponsePromise = api.forms.put(`${id}/formobject?formobject=${category}`, {
				formObject: mergedValues
			});

			const projectStatusPromise = api.projects.put(`${this.state.activeFormData.id}/status`, {
				status: `${this.state.projectStatus}`
			});

			const formResponse = await formResponsePromise;

			const projectStatus = await projectStatusPromise;

			await Promise.all([this.handleSetCategoryFormDataAndProjectStatus(formResponse, projectStatus)]);
		} catch (error) {
			this.handleSetServerError(error);
		}
	};

	handleSetCategoryFormDataAndProjectStatus = (categoryFormData, projectStatus) => {
		this.setState((prevState) => ({
			...prevState,
			categoryFormData,
			projectStatus: projectStatus.status,
			successMessage: true,
			errorMessage: false,
			isFetching: false
		}));
		setTimeout(() => this.setState({ successMessage: false }), 1500);
	};

	handleCommentGallery = (ev, galleryID) => {
		ev.preventDefault();

		if (this.state.dbImageThumbs.length) {
			const imageArray = this.state.dbImageThumbs.map((imageThumbEntries) =>
				imageThumbEntries.entries.filter((imageThumb) => {
					if (imageThumb.metadata && imageThumb.metadata.name.includes(galleryID)) {
						return imageThumb;
					}
				})
			);

			const images = imageArray.filter((imageArr) => imageArr.length);
			this.setState((prevState) => ({
				...prevState,
				showCommentGallery: images
			}));
		}
	};

	handleCloseGallery = (ev) => {
		ev.preventDefault();
		this.setState((prevState) => ({ ...prevState, showCommentGallery: null }));
	};

	handleCommentEditMode = (e, commentID) => {
		e.preventDefault();
		const commentData = this.state.comments.filter((comment) => comment.id === commentID);

		const { comment, id, group, target_id } = commentData[0];
		this.setState(
			(prevState) => ({
				...prevState,
				selectedComment: comment,
				selectedCommentID: id,
				commentTargetGroup: group,
				commentTargetId: target_id,
				toggleCommentForm: true,
				showComments: true
			}),
			() => (document.body.style.overflowY = 'hidden')
		);
	};

	handleCommentDelete = async (ev, commentId) => {
		ev.preventDefault();
		const filterComment = this.state.comments.filter((comment) => comment.id === commentId);

		const { id } = filterComment[0];
		try {
			const response = await api.general.delete(`/comment/`, { id });
			if (response.status === true) {
				this.handleRemoveCommentState(filterComment[0]);
			}
		} catch (error) {
			this.setServerError((prevState) => ({
				...prevState,
				error,
				isFetching: false
			}));
		}
	};

	handleRemoveCommentState = (deleteComment) => {
		const comments = this.state.comments.filter((comment) => comment.id != deleteComment.id);

		const selectCommentGroups = comments.map((comment) => comment.group);

		const uniqueGroups = selectCommentGroups.filter((v, i, a) => a.indexOf(v) === i);

		const uniqueTargetIDs = Object.values(
			comments.reduce((acc, cur) => Object.assign(acc, { [cur.target_id]: cur }), {})
		);

		this.setState((prevState) => ({
			...prevState,
			comments,
			commentGroups: uniqueGroups,
			uniqueTargetIDs
		}));
	};

	handleToggleDeleteConfirm = (ev, commentId) => {
		ev.preventDefault();
		this.setState((prevState) => ({
			...prevState,
			showDeleteConfirmID: commentId
		}));
	};

	handleSelectGroupChange = (ev) => {
		ev.preventDefault();
		if (ev.target.value) {
			this.setState({ commentTargetGroup: ev.target.value });
		}
	};

	handleCommentChange = (ev) => {
		this.setState({ selectedComment: ev.target.value });
	};

	handleCommentToggleForm = (ev) => {
		ev.preventDefault();
		this.setState((prevState) => ({
			...prevState,
			toggleCommentForm: true
		}));
	};

	handleCommentSubmit = async (ev) => {
		let { commentTargetId, commentTargetGroup, selectedCommentID, commentLabel } = this.state;

		const projectID = this.state.activeFormData.id;

		const commentGroupPattern = '^[^.]+';

		if (commentTargetGroup == 'show_all' || commentTargetGroup == 'general_comment') {
			commentTargetGroup = 'general_comment';
			commentTargetId = 'general_comment';
			commentLabel = 'General comments';
		} else if (commentTargetGroup == 'delivery_and_project') {
			commentTargetGroup = 'delivery_and_project';
		} else if (commentTargetGroup == 'demolition_and_services') {
			commentTargetGroup = 'demolition_and_services';
		} else if (commentTargetId.match(commentGroupPattern)) {
			const matchResult = commentTargetId.match(commentGroupPattern);
			commentTargetGroup = matchResult[0];
		}

		try {
			const result = await api.general.put(`comment`, {
				id: selectedCommentID,
				target_id: commentTargetId,
				comment: ev.comment,
				project_id: projectID,
				group: commentTargetGroup,
				label: commentLabel
			});

			if (result) {
				if (this.state.uploadDoc || this.state.uploadImg) {
					this.handleSendDbData('', result.id, projectID);
				}

				if (selectedCommentID) {
					this.handleEditComment(result);
				} else {
					this.handleUpdateComments(result);
				}
			}
		} catch (error) {
			this.handleSetServerError(error);
		}
	};

	handleEditComment = (result) => {
		const filteredComments = this.state.comments.map((comment) => {
			if (comment.id === result.id) {
				comment = result;
			}
			return comment;
		});

		const sortedComments = filteredComments.sort((a, b) =>
			a.target_id > b.target_id ? 1 : b.modified_datetime < a.modified_datetime ? -1 : 0
		);

		this.setState((prevState) => ({
			selectedComment: '',
			selectedCommentID: null,
			comments: sortedComments,
			toggleCommentForm: false
		}));
	};

	handleUpdateComments = (result) => {
		const { group } = result;

		const allComments = [...this.state.comments, result].sort((a, b) =>
			a.target_id > b.target_id ? 1 : b.target_id < a.target_id ? -1 : 0
		);

		const uniqueTargetIDs = Object.values(
			allComments.reduce((acc, cur) => Object.assign(acc, { [cur.target_id]: cur }), {})
		);

		this.setState(
			(prevState) => ({
				...prevState,
				toggleCommentForm: false,
				selectedComment: '',
				comments: [result, ...prevState.comments].sort((a, b) =>
					a.target_id > b.target_id ? 1 : b.target_id < a.target_id ? -1 : 0
				),
				commentGroups: [...prevState.commentGroups, group].filter((v, i, a) => a.indexOf(v) === i),
				uniqueTargetIDs
			}),
			() => this.scrollToCommentID()
		);
	};

	async componentDidMount() {
		const { currentUser } = this.props;
		this.props.fetchUserRoleStart({ id: currentUser.id });
		const { projectId } = this.props.match.params;
		try {
			const accessToken = 'iJKqyjtIgiAAAAAAAAAA1LQ5yhAZHOrvK7FAI62u_x0mCfOc6UOTOg825Wd1JSD9';

			const coreFolder = "Prosjekter"; // This is the main TeamFolder Workspace name

			const targetFolder = 'Prosjekter';

			const dropBoxTeam = new DropboxTeam({
				fetch,
				accessToken
			});

			const membersPromise = dropBoxTeam.teamMembersList();

			const team_foldersPromise = dropBoxTeam.teamTeamFolderList({
				limit: this.state.dbLimit
			});

			const { members } = await membersPromise;

			const { team_folders } = await team_foldersPromise;

			const projectFolder = team_folders.find((teamFolder) => teamFolder.name == coreFolder);

			const dbPathRoot = `{".tag": "namespace_id","namespace_id": "${projectFolder.team_folder_id}"}`;

			const dbCurrentUser = members.find((user) => user.profile.email == this.props.currentUser.email);

			const clientsPromise = api.clients.get();

			const availableRolesPromise = api.users.get(`?includes=availableroles`);

			const addressPromise = api.clients.get(`address?project_id=${projectId}`);

			const servicePromise = api.projects.get(`services?project_id=${projectId}`);

			const servicePersonPromise = api.projects.get(`servicespeople?project_id=${projectId}`);

			const serviceTypeAndMaterialPromise = api.projects.get(`formelementvalues/${projectId}`);

			const projectPromise = api.projects.get(`${projectId}?show_knapphus_brand=1&includes=parent_ids_available`);

			const commentsPromise = api.general.get(`comment/?project_id=${projectId}&sort=-group,-modified_datetime`);

			const trackedTimePromise = api.timetracks.get(`trackedtime/${projectId}`);

			const allUsersPromise = api.users.get();

			const clients = await clientsPromise;

			const availableRoles = await availableRolesPromise;

			const address = await addressPromise;

			const service = await servicePromise;

			const servicePerson = await servicePersonPromise;

			const serviceTypeAndMaterial = await serviceTypeAndMaterialPromise;

			const allUsers = await allUsersPromise;

			const project = await projectPromise;

			const comments = await commentsPromise;

			const selectCommentGroups = comments.map((comment) => comment.group);

			const uniqueGroups = selectCommentGroups.filter((v, i, a) => a.indexOf(v) === i);

			const uniqueTargetIDs = Object.values(
				comments.reduce((acc, cur) => Object.assign(acc, { [cur.target_id]: cur }), {})
			);

			const showServices = [
				...service.filter((service) => service.active_service == 'checked'),
				...servicePerson.filter((person) => person.active_service == 'checked')
			];

			const categoryFormDataPromise = api.forms.getForm(project.id, `${project.category}`);

			const dbFolderStructure = this.handleCreateDbObject(project, targetFolder, clients);

			const projectDropboxPromise = api.dropbox.get(`?project_id=${projectId}`);

			const projectDropbox = await projectDropboxPromise;

			const newUserBasedDbxObject = dbObject(dbCurrentUser, dbPathRoot);

			const dbImagesPromise = newUserBasedDbxObject.filesListFolder({
				path: projectDropbox[1]?.dbx_id ? `${projectDropbox[1]?.dbx_id}` : `${dbFolderStructure.img}`
			});

			const dbFilesPromise = newUserBasedDbxObject.filesListFolder({
				path: projectDropbox[0]?.dbx_id ? `${projectDropbox[0]?.dbx_id}` : `${dbFolderStructure.docs}`
			});

			const projectDBimg = projectDropbox[1]?.dbx_id;

			const projectDBDoc = projectDropbox[0]?.dbx_id;

			const projectStatus = project.status;

			const dbImages = await dbImagesPromise;

			const dbFiles = await dbFilesPromise;

			const categoryFormData = await categoryFormDataPromise;

			const trackedTime = await trackedTimePromise;

			await Promise.all([
				this.handleSetInitialState(
					project,
					clients,
					availableRoles,
					address,
					service,
					servicePerson,
					serviceTypeAndMaterial,
					showServices,
					comments,
					uniqueGroups,
					uniqueTargetIDs,
					dbCurrentUser,
					dbPathRoot,
					dbFolderStructure,
					dbImages.entries,
					dbFiles.entries,
					allUsers,
					categoryFormData,
					projectStatus,
					trackedTime,
					projectDBimg,
					projectDBDoc
				)
			]);
		} catch (error) {
			this.handleSetServerError(error);
		}
	}

	handlePageOneData = async (ev) => {
		ev.preventDefault();
		this.setState((prevState) => ({ isFetching: true }));
		const { activeFormData } = this.state;
		if (activeFormData.id) {
			try {
				const projectPromise = api.projects.get(`${activeFormData.id}?show_knapphus_brand=1`);

				const addressPromise = api.clients.get(`address/?project_id=${activeFormData.id}`);

				const servicesPromise = api.projects.get(`services/?project_id=${activeFormData.id}`);

				const servicePersonPromise = api.projects.get(`servicespeople?project_id=${activeFormData.id}`);

				const project = await projectPromise;

				const address = await addressPromise;

				const services = await servicesPromise;

				const servicePersons = await servicePersonPromise;

				const showServices = [
					...services.filter((service) => service.active_service == 'checked'),
					...servicePersons.filter((person) => person.active_service == 'checked')
				];
				await Promise.all([this.handlePageOneState(project, address, services, servicePersons, showServices)]);
			} catch (error) {
				this.handleSetServerError(error);
			}
		}
	};

	handlePageTwoData = async (ev) => {
		ev.preventDefault();
		const { id, category } = this.state.activeFormData;
		this.setState((prevState) => ({ isFetching: true }));
		try {
			const categoryFormData = await api.forms.getForm(id, category);

			this.handlePageTwoState(categoryFormData);
		} catch (error) {
			this.handleSetServerError(error);
		}
	};

	handleRemoveAddress = async (remove, iterationKey, id) => {
		try {
			if (id == undefined) {
				const filterAddressData = this.state.activeAddressData.filter((address) => {
					if (address['iteration'] == iterationKey) {
						return address;
					}
				});

				const { status } = await api.clients.delete('/address', {
					id: `${filterAddressData[0]['id']}`
				});
				if (status === true) {
					remove();
					const filterAddressData = this.state.activeAddressData.filter((address) => {
						if (address['iteration'] != iterationKey) {
							return address;
						}
					});
					this.setState((prevState) => ({
						...prevState,
						activeAddressData: filterAddressData
					}));
				}
				return;
			}
			const { status } = await api.clients.delete('/address', {
				id
			});
			if (status === true) {
				remove();
				const filterAddressData = this.state.activeAddressData.filter((address) => {
					if (address['id'] != id) {
						return address;
					}
				});
				this.setState((prevState) => ({
					...prevState,
					activeAddressData: filterAddressData
				}));
			}
		} catch (error) {
			this.handleSetServerError(error);
		}
	};

	handleCreateDbObject = (project, targetFolder, serverClientList) => {
		const { id, parent_id, title, client_id } = project;

		const filterClient = serverClientList.filter((serverClient) => serverClient.id == client_id);

		let folderStructure;

		if (parent_id) {
			folderStructure = {
				docs: `/${targetFolder}/(${parent_id}) ${filterClient[0].title}/(${id}) ${title}/docs`,
				img: `/${targetFolder}/(${parent_id}) ${filterClient[0].title}/(${id}) ${title}/img`
			};
		} else {
			folderStructure = {
				docs: `/${targetFolder}/(${id}) ${filterClient[0].title}/docs`,
				img: `/${targetFolder}/(${id}) ${filterClient[0].title}/img`
			};
		}

		return folderStructure;
	};

	handleSetInitialState = (
		activeFormData,
		serverClientList,
		serverUserRoles,
		activeAddressData,
		serviceList,
		servicePersonList,
		serviceTypeAndMaterial,
		showServices,
		comments,
		commentGroups,
		uniqueTargetIDs,
		dbCurrentUser,
		dbPathRoot,
		dbFolderStructure,
		dbImages,
		dbFiles,
		allUsers,
		categoryFormData,
		projectStatus,
		trackedTime,
		projectDBimg,
		projectDBDoc
	) => {
		this.setState((prevState) => ({
			...prevState,
			serverClientList,
			activeFormData,
			serverUserRoles,
			activeAddressData,
			serviceList,
			servicePersonList,
			serviceTypeAndMaterial,
			showServices,
			comments,
			commentGroups,
			uniqueTargetIDs,
			dbCurrentUser,
			dbPathRoot,
			dbFolderStructure,
			dbImages,
			dbFiles,
			allUsers,
			categoryFormData,
			projectStatus,
			trackedTime,
			projectDBimg,
			projectDBDoc,
			isFetching: false,
			error: null
		}));
	};

	handleSetNextPageState = (activeFormData, serviceList, servicePersonList, activeAddressData, categoryFormData) => {
		this.setState((prevState) => ({
			...prevState,
			activeFormData,
			serviceList,
			servicePersonList,
			activeAddressData,
			categoryFormData,
			successMessage: true,
			isFetching: false,
			error: null
		}));
		setTimeout(() => this.setState({ successMessage: false }), 1500);
	};

	handlePageOneState = (activeFormData, activeAddressData, serviceList, servicePersonList, showServices) => {
		this.setState(
			(prevState) => ({
				...prevState,
				activeFormData,
				serviceList,
				servicePersonList,
				activeAddressData,
				showServices,
				page: 1,
				isFetching: false,
				error: null
			}),
			() => {
				window.scrollTo(0, 0);
			}
		);
	};

	handlePageTwoState = (categoryFormData) => {
		this.setState(
			(prevState) => ({
				...prevState,
				categoryFormData,
				page: 2,
				isFetching: false,
				error: null
			}),
			() => {
				window.scrollTo(0, 0);
			}
		);
	};

	handleSetServerError = (error) => {
		this.setState((prevState) => ({
			...prevState,
			isFetching: false,
			error,
			errorMessage: true
		}));
	};

	handleSetServicesToUnchecked = (serviceData, projectID) => {
		if (serviceData.length) {
			return serviceData.filter((service) => {
				if (service.checkbox === true) {
					service.project_id = projectID;
					service.active_service = 'unchecked';
					return service;
				}
			});
		}
	};

	handleModifiedServiceAndPerson = (serviceData, projectID) => {
		if (serviceData.length) {
			const serviceDataMap = serviceData.map((service) => {
				if (service.checkbox === true) {
					service.project_id = projectID;
					service.active_service = 'checked';
					return service;
				}
				service.active_service = 'unchecked';
				return service;
			});

			return serviceDataMap.filter((service) => {
				if (service.checkbox) {
					return service;
				}
			});
		}
	};

	handleOnSubmit = async (values) => {
		const {
			end_assembly_datetime,
			start_assembly_datetime,
			end_production_datetime,
			start_production_datetime,
			end_transport_datetime,
			start_transport_datetime
		} = this.state.activeFormData;

		this.setState((prevState) => ({ isFetching: true }));

		try {
			let {
				client_address,
				ceiling,
				ceiling_list,
				floor,
				floor_list,
				window_list,
				electrician,
				plumber,
				construction_manager,
				demolition_and_services
			} = values;

			const project = await api.projects.put(values.id, {
				...values,
				end_assembly_datetime,
				start_assembly_datetime,
				end_production_datetime,
				start_production_datetime,
				end_transport_datetime,
				start_transport_datetime
			});

			const categoryFormDataPromise = api.forms.getForm(project.id, `${project.category}`);

			const addressesWithProjectId = this.state.activeAddressData.map((address) => {
				address['project_id'] = values.id;
				return address;
			});
			if (client_address === undefined) {
				client_address = [];
			}
			const addressesWithProjectId2 = client_address.map((address) => {
				address['project_id'] = values.id;
				return address;
			});

			const mergedAddresses = _.merge(addressesWithProjectId, addressesWithProjectId2);

			const addressPromise = api.clients.put('address', [...mergedAddresses]);

			let modifiedServices = [];

			let modifiedServicePersons = [];

			if (demolition_and_services) {
				modifiedServices = this.handleModifiedServiceAndPerson(
					[ceiling, ceiling_list, floor, floor_list, window_list],
					project.id
				);
				modifiedServicePersons = this.handleModifiedServiceAndPerson(
					[electrician, plumber, construction_manager],
					project.id
				);
			} else {
				modifiedServices = this.handleSetServicesToUnchecked(
					[ceiling, ceiling_list, floor, floor_list, window_list],
					project.id
				);
				modifiedServicePersons = this.handleSetServicesToUnchecked(
					[electrician, plumber, construction_manager],
					project.id
				);
			}

			let returnedServices = [];

			let returnedServicePersons = [];

			if (modifiedServices.length) {
				const returnedServicesPromise = api.projects.put('services', modifiedServices);
				returnedServices = await returnedServicesPromise;
			}
			if (modifiedServicePersons.length) {
				const returnedServicePersonsPromise = api.projects.put('servicespeople', modifiedServicePersons);
				returnedServicePersons = await returnedServicePersonsPromise;
			}

			const address = await addressPromise;

			const categoryFormData = await categoryFormDataPromise;
			await Promise.all([
				this.handleSetNextPageState(
					project,
					returnedServices,
					returnedServicePersons,
					address,
					categoryFormData
				)
			]);
		} catch (error) {
			this.handleSetServerError(error);
		}
	};

	handleShowComments = (showComments) => {
		this.setState(
			(prevState) => ({
				...prevState,
				commentTargetId: showComments.targetId,
				commentTargetGroup: showComments.group,
				commentLabel: showComments.label,
				showDeleteConfirmID: false,
				showComments: true,
				toggleCommentForm: true
			}),
			() => {
				this.scrollToCommentID();
				document.body.style.overflowY = 'hidden';
			}
		);
	};

	handleHideCommentForm = () => {
		this.setState((prevState) => ({
			...prevState,
			toggleCommentForm: false
		}));
	};

	scrollToCommentID = () => {
		const element = document.getElementById(this.state.commentTargetId);
		if (element) {
			element.scrollIntoView({ behavior: 'smooth' });
		}
	};

	handleHideComments = (ev) => {
		ev.preventDefault();
		this.setState(
			(prevState) => ({
				...prevState,
				showComments: false,
				showDeleteConfirmID: false,
				toggleCommentForm: false,
				showCommentGallery: null
			}),
			() => (document.body.style.overflowY = 'auto')
		);
	};

	handleDropZoneAttachmentChange = (files) => {
		if (files) {
			const applicationFiles = files.filter((file) => {
				if (file.type.match(/application/)) {
					return file;
				}
			});

			const imageFiles = files.filter((file) => {
				if (file.type.match(/image/)) {
					return file;
				}
			});

			if (applicationFiles.length) {
				this.setState({
					uploadDoc: applicationFiles?.map((file) => {
						return [file];
					})
				});
			} else {
				this.setState({ uploadDoc: [] });
			}
			if (imageFiles.length) {
				this.setState({
					uploadImg: imageFiles?.map((file) => {
						return [file];
					})
				});
			} else {
				this.setState({ uploadImg: [] });
			}
		}
	};

	handleAttachmentChange = (ev) => {
		const fileToArray = Object.keys(ev.target.files).map(function(key) {
			return [ev.target.files[key]];
		});

		if (ev.target.files) {
			const applicationFiles = fileToArray.filter((file) => {
				if (file[0].type.match(`application.*`)) {
					return file;
				}
			});

			const imageFiles = fileToArray.filter((file) => {
				if (file[0].type.match(`image.*`)) {
					return file;
				}
			});

			if (applicationFiles.length) {
				this.setState({ uploadDoc: applicationFiles });
			} else {
				this.setState({ uploadDoc: [] });
			}
			if (imageFiles.length) {
				this.setState({ uploadImg: imageFiles });
			} else {
				this.setState({ uploadImg: [] });
			}
		}
	};

	handleImageThumbs = (images) => {
		const newUserBasedDbxObject = dbObject(this.state.dbCurrentUser, this.state.dbPathRoot);

		const modifiedEntries = images.map((entry) => {
			return {
				path: entry.id,
				format: { '.tag': 'jpeg' },
				size: { '.tag': 'w2048h1536' },
				mode: { '.tag': 'strict' }
			};
		});

		const fileBatch = function(arg1, arg2) {
			return modifiedEntries.filter((key, i) => {
				if (i >= arg1 && i <= arg2) {
					return key;
				}
			});
		};

		const thumbImagesPromise = (arg1, arg2) =>
			newUserBasedDbxObject.filesGetThumbnailBatch({
				entries: fileBatch(arg1, arg2)
			});

		const getThumbs = async (arg1, arg2) => {
			return await thumbImagesPromise(arg1, arg2);
		};

		const thumbArray = [];
		for (let i = 0; i < modifiedEntries.length; i += 25) {
			thumbArray.push(getThumbs(i, i + 24).then((res) => res));
		}

		Promise.all(thumbArray).then((res) =>
			this.setState((prevState) => ({
				...prevState,
				dbImageThumbs: [...res],
				isUploadingImg: false
			}))
		);
	};

	handleSendDbData = (ev, id, projectID) => {
		if (ev) {
			ev.preventDefault();
		}
		try {
			const accessToken = 'iJKqyjtIgiAAAAAAAAAA1LQ5yhAZHOrvK7FAI62u_x0mCfOc6UOTOg825Wd1JSD9';

			const folderStructure = this.state.dbFolderStructure;

			const newUserBasedDbxObject = new Dropbox({
				fetch,
				accessToken,
				selectUser: this.state.dbCurrentUser.profile.team_member_id,
				pathRoot: this.state.dbPathRoot
			});

			if (this.state.uploadDoc.length) {
				const docUploadPromise = this.handleDropBoxUpload(
					newUserBasedDbxObject,
					this.state.uploadDoc,
					this.state.projectDBDoc ? this.state.projectDBDoc : folderStructure.docs,
					id,
					projectID
				);
				this.setState({ isUploadingDoc: true });
				Promise.all([...docUploadPromise])
					.then((res) => {
						this.setState((prevState) => ({
							dbFiles: [...prevState.dbFiles, ...res],
							uploadDoc: [],
							uploadImg: [],
							isUploadingDoc: false
						}));
					})
					.catch((error) =>
						this.setState(
							(prevState) => ({
								...prevState,
								uploadImg: [],
								uploadDoc: [],
								errorMessage: true,
								isUploadingDoc: false,
								error
							}),
							() => setTimeout(() => this.setState({ errorMessage: false }), 2000)
						)
					);
			}

			if (this.state.uploadImg.length) {
				const imgUploadPromise = this.handleDropBoxUpload(
					newUserBasedDbxObject,
					this.state.uploadImg,
					this.state.projectDBimg ? this.state.projectDBimg : folderStructure.img,
					id,
					projectID
				);
				this.setState({ isUploadingImg: true });
				Promise.all([...imgUploadPromise])
					.then((res) => {
						this.setState(
							(prevState) => ({
								dbImages: [...prevState.dbImages, ...res],
								uploadImg: [],
								uploadDoc: []
							}),
							() => this.handleImageThumbs(this.state.dbImages)
						);
					})
					.catch((error) =>
						this.setState(
							(prevState) => ({
								...prevState,
								uploadImg: [],
								uploadDoc: [],
								errorMessage: true,
								isUploadingImg: false,
								error
							}),
							() => setTimeout(() => this.setState({ errorMessage: false }), 2000)
						)
					);
			}
		} catch (error) {
			this.setState(
				(prevState) => ({
					...prevState,
					uploadImg: [],
					uploadDoc: [],
					errorMessage: true,
					isUploadingDoc: false,
					error
				}),
				() => setTimeout(() => this.setState({ errorMessage: false }), 2000)
			);
		}
	};

	handleDropBoxUpload = (user, fileArray, newProject, id, projectID) => {
		if (user === undefined || user === null) {
			return false;
		}

		const uploadArray = [];
		for (let i = 0; i < fileArray.length; i++) {
			const fileExtension = fileArray[i][0].name.substr(fileArray[i][0].name.lastIndexOf('.') + 1);

			let fileNameWithoutExtension = 'no-name';
			if (fileExtension) {
				fileNameWithoutExtension = fileArray[i][0].name.substr(
					0,
					fileArray[i][0].name.length - (fileExtension?.length + 1)
				);
			}
			const path =
				id && projectID
					? `${fileNameWithoutExtension} (${id} - ${projectID}).${fileExtension}`
					: `${fileNameWithoutExtension} (${this.state?.activeFormData?.id}).${fileExtension}`;

			uploadArray.push(
				user
					.filesUpload({
						contents: fileArray[i][0],
						path: `${newProject}/${path}`,
						autorename: true,
						mode: 'add',
						mute: false
					})
					.then((res) => res)
			);
		}

		return uploadArray;
	};

	handleDocumentDownload = (ev, file) => {
		ev.preventDefault();
		const newUserBasedDbxObject = new Dropbox({
			fetch,
			accessToken,
			selectUser: this.state.dbCurrentUser.profile.team_member_id,
			pathRoot: this.state.dbPathRoot
		});

		const fileDownload = newUserBasedDbxObject.filesDownload({
			path: `${file.path_lower}`
		});

		const FileDownload = require('js-file-download');
		fileDownload.then((res) => {
			FileDownload(res.fileBlob, `${res.name}`);
		});
	};

	render() {
		const { currentUserRoles } = this.props;

		const canSetArchiveStatusFilter = currentUserRoles.filter((role) => role.label === 'admin');

		const {
			isFetching,
			page,
			showComments,
			serverClientList,
			successMessage,
			errorMessage,
			toggleCalendar,
			handleAddNewClient,
			showAddNewClientOverlay,
			handleCreateNewClient,
			error,
			projectStatus,
			trackedTime
		} = this.state;

		return (
			<>
				<CSSTransition
					in={isFetching}
					appear
					timeout={{
						appear: 300,
						exit: 150
					}}
				>
					<Spinner />
				</CSSTransition>
				<CSSTransition
					in={successMessage}
					appear
					timeout={{
						enter: 3000,
						exit: 0
					}}
				>
					<SuccessMessage message={GeneralConstants['success']} />
				</CSSTransition>
				{showAddNewClientOverlay && (
					<ProjectNewClient
						handleAddNewClient={handleAddNewClient}
						handleCreateNewClient={handleCreateNewClient}
						serverError={error}
					/>
				)}
				{this.state.error && this.state.error.id === 0 && this.state.error.http_code === 404 ? (
					<NotFound />
				) : (
					this.state.error &&
					this.state.error.http_code !== 409 &&
					errorMessage && (
						<CSSTransition
							in={errorMessage}
							appear
							timeout={{
								appear: 3000,
								exit: 0
							}}
						>
							<ErrorMessage
								errorID={
									this.state.error && this.state.error.id
										? this.state.error.id
										: this.state.error && this.state.error.status
										? this.state.error.status
										: ''
								}
							/>
						</CSSTransition>
					)
				)}
				<EditFormContext.Provider value={{ ...this.state }}>
					{page === 1 && serverClientList.length && (
						<ProjectFormFirstPage {...this.state} canSetArchiveMode={canSetArchiveStatusFilter.length} />
					)}
					{page === 2 && (
						<ProjectFormSecondPage {...this.state} canSetArchiveMode={canSetArchiveStatusFilter.length} />
					)}
					{page === 3 && (
						<ProjectFormThirdPage {...this.state} canSetArchiveMode={canSetArchiveStatusFilter.length} />
					)}

					{showComments ? (
						<CSSTransition
							in={showComments}
							appear
							timeout={{
								appear: 3000,
								exit: 1000000000
							}}
						>
							<CommentContainer />
						</CSSTransition>
					) : null}
					{toggleCalendar ? (
						<CSSTransition
							in={toggleCalendar}
							appear
							timeout={{
								appear: 3000,
								exit: 1000000000
							}}
						>
							<ProjectCalendar {...this.state} />
						</CSSTransition>
					) : null}
				</EditFormContext.Provider>
			</>
		);
	}
}

const mapStateToProps = createStructuredSelector({
	currentUser: selectCurrentUser,
	currentUserRoles: selectCurrentUserRoles
});

const mapDispatchToProps = (dispatch) => ({
	fetchUserRoleStart: (id) => dispatch(fetchUserRoleStart(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProject);
