import { Dropbox, DropboxTeam } from 'dropbox';
import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { history } from '../../helpers';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import ErrorMessage from '../error/error.component';
import ProjectFormStartPage from '../project-form-steps/project-form-start-page.component';
import ProjectNewClient from '../project-new-client/project-new-client.component';
import ProjectNewGroup from '../project-new-group/project-new-group.component';
import Spinner from '../spinner/spinner.component';

const api = window.api;

class AddProject extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			serverClientList: [],
			parentProjects: [],
			dbLimit: 20,
			dbPathRoot: '',
			dbCurrentUser: {},
			dbFolderStructure: '',
			isFetching: true,
			error: null,
			errorMessage: false,
			handleOnSubmit: this.handleOnSubmit,
			handleCreateNewClient: this.handleCreateNewClient,
			handleAddNewClient: this.handleAddNewClient,
			showAddNewClientOverlay: false,
			handleCreateNewProjectGroup: this.handleCreateNewProjectGroup,
			handleAddNewProjectGroup: this.handleAddNewProjectGroup,
			showAddNewProjectGroupOverlay: false,
			newlyCreatedClient: ''
		};
	}

	handleCreateNewProjectGroup = (ev) => {
		ev.preventDefault();
		this.setState((prevState) => ({
			...prevState,
			showAddNewProjectGroupOverlay: !prevState.showAddNewProjectGroupOverlay,
			error: null
		}));
	};

	handleCreateNewClient = (ev) => {
		ev.preventDefault();
		this.setState((prevState) => ({
			...prevState,
			showAddNewClientOverlay: !prevState.showAddNewClientOverlay,
			error: null
		}));
	};

	handleAddNewClient = (values) => {
		api.clients
			.put('clients', { ...values })
			.then((response) =>
				this.setState((prevState) => ({
					...prevState,
					serverClientList: [response, ...prevState.serverClientList],
					showAddNewClientOverlay: false,
					error: null,
					newlyCreatedClient: response
				}))
			)
			.catch((error) => this.setServerError(error));
	};

	handleAddNewProjectGroup = (values) => {
		api.projects
			.put('parent', { ...values })
			.then((response) => {
				this.setState((prevState) => ({
					...prevState,
					parentProjects: [response, ...prevState.parentProjects],
					showAddNewProjectGroupOverlay: false,
					error: null
				}));
			})
			.catch((error) => this.setServerError(error));
	};

	async componentDidMount() {
		try {
			const accessToken = 'iJKqyjtIgiAAAAAAAAAA1LQ5yhAZHOrvK7FAI62u_x0mCfOc6UOTOg825Wd1JSD9';

			const coreFolder = "Prosjekter"; // This is the main TeamFolder Workspace name

			const dropBoxTeam = new DropboxTeam({
				fetch,
				accessToken
			});

			const membersPromise = dropBoxTeam.teamMembersList();

			const team_foldersPromise = dropBoxTeam.teamTeamFolderList({
				limit: this.state.dbLimit
			});

			const { members } = await membersPromise;

			const { team_folders } = await team_foldersPromise;

			const projectFolder = team_folders.find((teamFolder) => teamFolder.name == coreFolder);

			const dbPathRoot = `{".tag": "namespace_id","namespace_id": "${projectFolder.team_folder_id}"}`;

			const dbCurrentUser = members.find((user) => user.profile.email == this.props.currentUser.email);

			const parentProjectsPromise = api.projects.getParents();

			const serverClientListPromise = api.clients.get('?sort=+title');

			const serverClientList = await serverClientListPromise;

			const parentProjects = await parentProjectsPromise;

			await Promise.all([this.setInitialState(serverClientList, parentProjects, dbCurrentUser, dbPathRoot)]);
		} catch (error) {
			this.setServerError(error);
		}
	}

	setInitialState = (serverClientList, parentProjects, dbCurrentUser, dbPathRoot) => {
		this.setState((prevState) => ({
			...prevState,
			serverClientList,
			parentProjects: [...prevState.parentProjects, ...parentProjects],
			dbCurrentUser,
			dbPathRoot,
			isFetching: false,
			error: null
		}));
	};

	redirectToProjectActive = (project, dbFolderStatus) => {
		history.push(`/project-active/${project.id}`);
	};

	setServerError = (error) => {
		this.setState((prevState) => ({
			...prevState,
			isFetching: false,
			error,
			errorMessage: true
		}));
		setTimeout(() => this.setState({ errorMessage: false }), 3000);
	};

	createDbObject = (project, targetFolder, serverClientList) => {
		const { id, parent_id, title, client_id } = project;

		const filterClient = serverClientList.filter((serverClient) => serverClient.id == client_id);

		let folderStructure;

		if (parent_id) {
			folderStructure = {
				docs: `/${targetFolder}/(${parent_id}) ${filterClient[0].title}/(${id}) ${title}/docs`,
				img: `/${targetFolder}/(${parent_id}) ${filterClient[0].title}/(${id}) ${title}/img`
			};
		} else {
			folderStructure = {
				docs: `/${targetFolder}/(${id}) ${filterClient[0].title}/docs`,
				img: `/${targetFolder}/(${id}) ${filterClient[0].title}/img`
			};
		}
		return folderStructure;
	};

	handleOnSubmit = async (values) => {
		this.setState((prevState) => ({ ...prevState, isFetching: true }));

		try {
			const project = await api.projects.put('', {
				...values
			});

			const { dbCurrentUser, dbPathRoot, serverClientList } = this.state;

			const targetFolder = 'Prosjekter';

			const accessToken = 'iJKqyjtIgiAAAAAAAAAA1LQ5yhAZHOrvK7FAI62u_x0mCfOc6UOTOg825Wd1JSD9';

			const createDbObject = this.createDbObject(project, targetFolder, serverClientList);

			const newUserBasedDbxObject = new Dropbox({
				fetch,
				accessToken,
				selectUser: dbCurrentUser.profile.team_member_id,
				pathRoot: dbPathRoot
			});

			const dbFolderPromise = newUserBasedDbxObject.filesCreateFolderBatch({
				paths: [createDbObject.img, createDbObject.docs]
			});

			const dbFolder = await dbFolderPromise;

			const saveToDropBoxDoc = await api.dropbox.put('', {
				dbx_id: dbFolder?.entries[0]?.metadata?.id,
				dbx_path_lower: dbFolder?.entries[0]?.metadata?.path_lower,
				project_id: project.id
			});

			const saveToDropBoxImg = await api.dropbox.put('', {
				dbx_id: dbFolder?.entries[1]?.metadata?.id,
				dbx_path_lower: dbFolder?.entries[1]?.metadata?.path_lower,
				project_id: project.id
			});

			await Promise.all([this.redirectToProjectActive(project, dbFolder, saveToDropBoxDoc, saveToDropBoxImg)]);
		} catch (error) {
			this.setServerError(error);
		}
	};

	render() {
		const {
			isFetching,
			errorMessage,
			error,
			showAddNewClientOverlay,
			handleAddNewClient,
			handleCreateNewClient,
			serverClientList,
			showAddNewProjectGroupOverlay,
			handleCreateNewProjectGroup,
			handleAddNewProjectGroup
		} = this.state;
		return (
			<>
				{isFetching && <Spinner />}
				{errorMessage && <ErrorMessage errorID={error?.id ? error.id : error?.status ? error.status : ''} />}
				{showAddNewClientOverlay && (
					<ProjectNewClient
						handleAddNewClient={handleAddNewClient}
						handleCreateNewClient={handleCreateNewClient}
						serverError={error}
					/>
				)}
				{showAddNewProjectGroupOverlay && (
					<ProjectNewGroup
						handleCreateNewProjectGroup={handleCreateNewProjectGroup}
						handleAddNewProjectGroup={handleAddNewProjectGroup}
						serverError={error}
					/>
				)}
				{serverClientList.length > 0 && <ProjectFormStartPage {...this.state} />}
			</>
		);
	}
}

const mapStateToProps = createStructuredSelector({
	currentUser: selectCurrentUser
});

export default connect(mapStateToProps)(AddProject);
